.request-editor-heading {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-style: solid;
  border-color: #35393d;
  background-size: cover;
  padding-left: 0px !important;
}

.request-editor-heading h5 {
  font-family: Roboto;
  font-weight: 600;
  font-size: 18px;
  color: #d7d8d8;
  text-decoration: none solid rgb(215, 216, 216);
  line-height: 24px;
  line-height: 52px;
  margin-left: 20px;
  margin-right: 20px;
  margin-block-start: 4px;
  margin-block-end: 4px;
}

.request-editor-body {
  padding-top: 10px;
  padding-right: 20px;
  border-top-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-style: solid;
  border-color: #35393d;
  background-size: cover;
}

.request-editor-row {
  display: flex;
}

.display-flex {
  display: flex;
}

.right-justify {
  margin-left: auto;
  order: 2;
}

.request-editor-body {
  background-color: #1e2123;
}

.request-body-title {
  height: 60px;
  padding: 20px 0px 0px 5px;
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  color: #d7d8d8;
  text-decoration: none solid rgb(215, 216, 216);
  line-height: 20px;
}

textarea.form-control.request-body-area {
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 5px;
  resize: none;
}

@media (max-width: 612px) {
  textarea.form-control.request-body-area {
    min-width: 500px;
  }
}